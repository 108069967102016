import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RoutesService {
  static readonly ADMIN = 'admin';
  static readonly ADMIN_ROOT = `/${RoutesService.ADMIN}`;

  static readonly USER_MANAGEMENT = 'user-management';

  static readonly USERS = 'users';
  static readonly USERS_ROOT = `${RoutesService.ADMIN_ROOT}/${RoutesService.USER_MANAGEMENT}/${RoutesService.USERS}`;

  static readonly COMPANIES = 'companies';
  static readonly COMPANIES_ROOT = `${RoutesService.ADMIN_ROOT}/${RoutesService.USER_MANAGEMENT}/${RoutesService.COMPANIES}`;

  constructor() { }

  // Admin module
  getAdminRootPath(): string[] {
    return [RoutesService.ADMIN_ROOT];
  }

  getUserListPath(): string[] {
    return [RoutesService.ADMIN_ROOT, RoutesService.USER_MANAGEMENT, RoutesService.USERS];
  }

  getUserDetailPath(userId: number): string[] {
    return [RoutesService.ADMIN_ROOT, RoutesService.USER_MANAGEMENT, RoutesService.USERS, userId?.toString()];
  }

  getCompanyListPath(): string[] {
    return [RoutesService.ADMIN_ROOT, RoutesService.USER_MANAGEMENT, RoutesService.COMPANIES];
  }

  getCompanyDetailPath(id: number): string[] {
    return [RoutesService.ADMIN_ROOT, RoutesService.USER_MANAGEMENT, RoutesService.COMPANIES, id?.toString()];
  }



}
