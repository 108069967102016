import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';

import { ShellComponent } from './shell.component';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  imports: [
    RouterModule,
    SharedModule
  ],
  declarations: [
    ShellComponent,
    HeaderComponent,
    FooterComponent
  ],
  exports: [
    FooterComponent
  ]
})
export class ShellModule {
}
