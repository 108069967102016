import { Inject, Injectable } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';

import { Logger } from './logger.service';
import enUS from '../../../translations/en-US.json';
import esES from '../../../translations/es-ES.json';

import { languageSettings } from '@env/language-settings';

const log = new Logger('I18nService');
const languageKey = 'language';


export interface LanguageContext {
  code: string;
  label: string;
}

@Injectable({
  providedIn: 'root'
})
export class I18nService {

  defaultLanguage!: string;
  supportedLanguages!: LanguageContext[];

  private langChangeSubscription!: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService) {
    // Embed languages to avoid extra HTTP requests
    translateService.setTranslation('en-US', enUS);
    translateService.setTranslation('es-ES', esES);
  }

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   * @param defaultLanguage The default language to use.
   * @param supportedLanguages The list of supported languages.
   */
  init() {
    this.defaultLanguage = languageSettings.defaultLanguage;
    this.supportedLanguages = languageSettings.supportedLanguages;
    this.language = '';

    // Warning: this subscription will always be alive for the app's lifetime
    this.langChangeSubscription = this.translateService.onLangChange
      .subscribe((event: LangChangeEvent) => { localStorage.setItem(languageKey, event.lang); });
  }

  /**
   * Cleans up language change subscription.
   */
  destroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   * If no parameter is specified, the language is loaded from local storage (if present).
   * @param language The IETF language code to set.
   */
  set language(language: string) {
    language = language || localStorage.getItem(languageKey) || this.translateService.getBrowserCultureLang();
    const supportedLanguagesCodes = this.supportedLanguages.map(lang => lang.code);
    let isSupportedLanguage = supportedLanguagesCodes.includes(language);

    // If no exact match is found, search without the region
    if (language && !isSupportedLanguage) {
      language = language.split('-')[0];
      language = supportedLanguagesCodes.find(supportedLanguage => supportedLanguage.startsWith(language)) || '';
      isSupportedLanguage = Boolean(language);
    }

    // Fallback if language is not supported
    if (!isSupportedLanguage) {
      language = this.defaultLanguage;
    }

    log.debug(`Language set to ${language}`);
    this.translateService.use(language);


    // Dynamic upgrade language tag
    this.document.documentElement.lang = language.split('-')[0];
  }

  /**
   * Gets the current language.
   * @return The current language code.
   */
  get language(): string {
    return this.translateService.currentLang;
  }

}
