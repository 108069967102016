import { Routes, Route } from "@angular/router";

import { ShellComponent } from "./shell.component";

/**
 * Provides helper methods to create routes.
 */
export class Shell {
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static childRoutes(routes: Routes): Route {
    const _route: Route = {
      path: "",
      component: ShellComponent,
      // canActivate: [AuthGuard],
      // canActivateChild: [],
      children: routes,
    };

    return _route;
  }
}
