<div fxFlex class="shell-container">

          <!-- HEADER -->
          <app-header
            [isMobile]="isMobile"
          >
          </app-header>

          <!-- CONTENT -->
          <div
            class="mat-container content"
            ngClass="content-display-navigation"
            ngClass.sm="content-display"
            ngClass.xs="content-display-mobile"
          >
            <router-outlet></router-outlet>
          </div>

</div>
