import { Component, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import { Router } from '@angular/router';


@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent {
  @ViewChild('sidenavPrimary') sidenavPrimary: { toggle: () => void; };

  constructor(
    private media: MediaObserver,
    private router: Router
  ) {
    console.log(this.router);
   }
 
  get isCard(): boolean {
    console.log(this.router.url);
    const result = this.router.url.startsWith('/card/');
    console.log(result);
    return result;
  }

  get isMobile(): boolean {
    return this.media.isActive('xs') || this.media.isActive('sm');
  }



}
