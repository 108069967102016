// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
    production: false,
    serverUrl: 'https://tza8g959y5.execute-api.eu-west-1.amazonaws.com/vermon/api',
    landingUrl: 'https://contact.vermon.qrbcard.net',
    version: env.npm_package_version + '-vermon',
    aws: {
      storage: {
        s3: {
          region: 'eu-west-1',
          bucket: 'qrbcard.storage.vermon',
        }
      },
      notifications: {
        vapid_public_key: 'BNogUgDeZfmLBePrv423h5osdrlZ7S3ww9--IBJ-FR9ambPjEEk-y7ATy_rBzMJ2lOQYM0h8WWTqpkMt0q1mudQ'
      }
    },
    gcp: {
      gtm: {
        gtm_id: 'GTM-5H427KD',
        // gtm_auth: '',
        // gtm_preview: ''
      },
      recaptcha_key: '6Le0sDQiAAAAAP1ajtwoElXun--Lp0LCxxQRZso9'
    },
    cache: {
      Key: 'http_cache',
      Persistence: 'local', /* local / session / memory */
      Duration: 60*24 /* minutes */
    }
  };
