import { Title } from "@angular/platform-browser";
import { Component, Input } from "@angular/core";
import { UntilDestroy } from "@app/@core";

import { TranslateService } from "@ngx-translate/core";

@UntilDestroy()
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent {
  @Input() isMobile: Boolean = false;

  constructor(
    private titleService: Title,
    private translateService: TranslateService
  ) {}

  get title() {
    const title = this.titleService.getTitle();
    return title.replace(`${this.translateService.instant("app.name")} | `, "");
  }
}
