import { environment } from '@env/environment';
import { ApplicationRef, Injectable, Injector } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BehaviorSubject, Subscription, interval } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { Logger } from './@core';


import { Hub } from '@aws-amplify/core';
import { DeviceUUID } from "device-uuid";

import { AWSApiService } from './@core/services/aws/api.service';
import { NotificationService } from './@shared/services/notification.service';


const log = new Logger('AppService');


@Injectable({
  providedIn: 'root'
})
export class AppService {




  constructor() {



  }

  runServices() {


  }





}
