import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, ExtraOptions } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { RoutesService } from './@shared/services/routes.service';


const routes: Routes = [

  // [ROUTES] Application routes to business logic (lazy load)
  Shell.childRoutes([
    { path: '', loadChildren: () => import('./cards/cards.module').then(m => m.CardsModule) },
  ]),

  // Fallback when no prior route is matched
  { path: '**', redirectTo: '/', pathMatch: 'full' }
];


const routerOptions: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  anchorScrolling: 'enabled', // enables scrolling to an element with a corresponding ID if there is a fragment like #foo in the URL
};


@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
